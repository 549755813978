<template>
  <v-container fluid>
    <crm-table-pageable-new
      :headers="headers"
      :data="events"
      :config="config"
      :loaded-options="options"
      :total-elements="totalElements"
      :total-pages="totalPages"
      :available-filters.sync="availableFilters"
      :filters-dictionaries="filtersDictionaries"
      addButtonTitle="+ Создать"
      @onChangeData="onChangeData"
      @handleActions="handle"
    ></crm-table-pageable-new>
    <event-dialog v-if="dialog" v-model="dialog" :eventId="eventId" @close="close"></event-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Вы уверены что хотите удалить?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDelete">Отмена</v-btn>
          <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import EventDialog from '@/views/crm/marketing/components/EventDialog.vue';
import { mapState } from 'vuex';

export default {
  name: 'events',
  components: {
    EventDialog,
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      headers: [
        {
          value: 'id',
          sortable: false,
          text: 'Номер',
          type: 'defaultItem',
        },
        {
          value: 'createdDate',
          sortable: true,
          text: 'Дата создания',
          type: 'defaultItem',
        },
        {
          value: 'theme',
          sortable: false,
          text: 'Мероприятие',
          type: 'defaultItem',
        },
        {
          value: 'eventType',
          sortable: false,
          text: 'Тип',
          type: 'enums',
          enums: 'eventTypeEnums',
        },
        {
          value: 'budget',
          sortable: false,
          text: 'Бюджет',
          type: 'defaultItem',
        },
        {
          value: 'discount',
          sortable: false,
          text: 'Ставка',
          type: 'defaultItem',
        },
        {
          value: 'totalAmount',
          sortable: false,
          text: 'Количество',
          type: 'defaultItem',
        },
        {
          value: 'startDate',
          sortable: false,
          text: 'Дата начала',
          type: 'defaultItem',
        },
        {
          value: 'finishDate',
          sortable: false,
          text: 'Дата окончания',
          type: 'defaultItem',
        },
        {
          value: 'user.name',
          sortable: false,
          text: 'Автор',
          type: 'defaultItem',
        },
        {
          value: 'status',
          sortable: false,
          text: 'Статус',
          type: 'enums',
          enums: 'eventStatusEnums',
        },
        {
          text: 'Действие',
          value: 'actions',
          type: 'actions',
          sortable: false,
          child: [
            {
              label: 'Редактировать',
              event: 'edit',
              icon: 'mdi-pencil',
              visible: true,
            },
            {
              label: 'Удалить',
              event: 'delete',
              icon: 'mdi-delete',
              visible: true,
            },
          ],
        },
      ],
      config: {
        add: true,
        search: true,
        searchPlaceholder: 'Поиск по теме мероприятия',
        pageable: true,
      },
      availableFilters: [
        {
          id: 'createdDateRange',
          name: 'Дата',
          type: 'dateRange',
          menu: false,
          active: false,
        },
        {
          id: 'statusList', name: 'Статус мерориятия', type: 'select', active: false,
        },
        {
          id: 'eventType', name: 'Тип мероприятия', type: 'select', active: false,
        },
      ],
      filtersDictionaries: {
        statusList: [
          { value: 'NEW', label: 'Новый' },
          { value: 'ACTIVE', label: 'Активный' },
          { value: 'FINISHED', label: 'Завершено' },
        ],
        eventType: [
          { label: 'Новый клиент', value: 'NEW_CLIENT' },
          { label: 'Персональный', value: 'PERSONAL' },
          { label: 'Общий', value: 'COMMON' },
        ],
      },
      totalElements: 0,
      totalPages: 0,
      url: '/api/v1/crm/event/search',
      options: {
        page: 1,
        size: 10,
        sort: 'createdDate,desc',
      },
      events: [],
      dialog: false,
      eventId: null,
      dialogDelete: false,
    };
  },
  mounted() {
    // this.getContractors();
    this.onChangeData(this.$route.query);
  },
  computed: {
    ...mapState(['user']),
    isChief() {
      return this.user.roles.includes('ROLE_CHIEF');
    },
  },
  methods: {
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router
        .replace({
          path: this.$route.path,
          params: {},
          query: resultOptions,
        })
        .catch(() => {});
      this.options = { ...resultOptions };
      this.getInputs();
    },
    getInputs() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$api
        .get(formattedUrl, {
          params: {
            page: params.page - 1,
            size: params.size,
            sort: params.sort,
            from: params.createdDateRange && params.createdDateRange.split(',')[0],
            to: params.createdDateRange && params.createdDateRange.split(',')[1],
            status: params.statusList,
            theme: params.search,
            eventType: params.eventType,
          },
        })
        .then((response) => {
          this.totalElements = response.totalElements;
          this.totalPages = response.totalPages;
          this.events = [...response.content].map((el) => ({
            ...el,
            createdDate: this.getFormattedDate(el.createdDate),
            startDate: this.getFormattedDate(el.startDate),
            finishDate: this.getFormattedDate(el.finishDate),
          }));
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    getFormattedDate(date) {
      if (!this.$moment(date).isValid()) return 'Нет данных';
      return this.$moment(date).format('DD.MM.YYYY');
    },
    handle(event) {
      switch (event.type) {
        case 'edit':
          if (this.isChief) break;
          this.openEditDialog(event.item);
          break;
        case 'create':
          if (this.isChief) break;
          this.openNewDialog();
          break;
        case 'delete':
          if (this.isChief) break;
          this.deleteConfirm(event.item);
          break;
        default:
          break;
      }
    },
    openNewDialog() {
      this.dialog = true;
    },
    async openEditDialog(item) {
      this.eventId = item.id;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.eventId = 0;
      this.onChangeData(this.$route.query);
    },
    deleteConfirm(item) {
      this.eventId = item.id;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.eventId = 0;
    },
    deleteItemConfirm() {
      this.$loading(true);
      this.$api
        .delete(`/api/v1/crm/event/delete/${this.eventId}`)
        .then(() => { this.onChangeData(this.$route.query); })
        .finally(() => {
          this.$loading(false);
          this.closeDelete();
          this.onChangeData(this.$route.query);
        });
    },

  },
};
</script>
<style lang="scss" scoped></style>
