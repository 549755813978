<template>
  <v-dialog
    v-model="value"
    width="550px"
    content-class="appeal-edit"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title style="background-color: #E0E0E0">
        <span>Промокод</span>
        <v-chip
          text-color="white"
          class="mx-3"
          small
          :color="eventStatusColorEnums[eventItem.status]"
        >
          {{ eventStatusEnums[eventItem.status] }}
        </v-chip>

        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle style="background-color: #E0E0E0">{{ date }}</v-card-subtitle>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row class="mt-3">
            <v-col>
              <v-text-field
                v-model="eventItem.theme"
                label="Тема"
                :rules="[v => !!v]"
                :readonly="readOnly"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                v-model="menuStartDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :readonly="readOnly"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="Дата начала"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[v => !!v]"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="eventItem.startDate"
                  :min="new Date().toISOString().slice(0, 10)"
                  :max="eventItem.endDate"
                  @input="menuStartDate = false"
                  :readonly="readOnly"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="menuFinishDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="finishDate"
                    label="Дата окончания"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[v => !!v]"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="eventItem.finishDate"
                  :min="eventItem.startDate"
                  @input="menuFinishDate = false"
                  :readonly="disabled"
                  :disabled="!eventItem.startDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model.number="eventItem.budget"
                type="number"
                label="Бюджет"
                :rules="budgetRule"
                :readonly="readOnly"
                @keydown="checkDot"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="eventItem.discount"
                type="number"
                label="Ставка"
                :rules="[v => !!v]"
                :readonly="readOnly"
                @keydown="checkDot"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="!eventItem.totalAmount">
              <v-text-field
                v-model.number="totalAmountComputed"
                type="number"
                label="Количество"
                :rules="[v => !!v]"
                readonly
              />
            </v-col>
            <v-col v-if="eventItem.totalAmount">
              <v-text-field
                v-model.number="eventItem.totalAmount"
                type="number"
                label="Количество"
                :rules="[v => !!v]"
                readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="eventItem.eventType"
                label="Тип"
                :items="eventTypes"
                item-value="value"
                item-text="text"
                :rules="[v => !!v]"
                :readonly="readOnly"
              />
            </v-col>
          </v-row>
          <v-row v-if="eventItem.eventType === 'PERSONAL'">
            <v-col>
              <v-text-field
                v-model="eventItem.phoneNumber"
                label="Телефон пользователя"
                v-mask="'+#(###)###-##-##'"
                :rules="[v => !!v]"
                :readonly="readOnly"
              />
            </v-col>
          </v-row>
          <v-row >
            <v-col cols="8">
              <v-text-field
                v-model="eventItem.promoCode"
                label="Промокод"
                :rules="[v => !!v]"
                :readonly="readOnly"
                @input="
                () => (eventItem.promoCode = eventItem.promoCode.toUpperCase())
                "
                @keydown="onlyEnglish"
              />
            </v-col>
            <v-col cols="4" align-self="center">
              <v-btn
                class="px-5"
                :disabled="loading"
                small
                @click="generatePromocode"
              >
                Сгенерировать
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="eventItem.message"
                :rows="4"
                filled
                label="Текст сообщения"
                placeholder="Введите текст сообщения"
                :rules="[v => !!v]"
                :readonly="readOnly"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p>Изображение</p>
              <div class="advertisement-content__image">
                <div v-if="!imageFrontUrl" class="advertisement-content__placeholder">
                  <v-btn @click="upload" width="100%" height="100%" text :disabled="readOnly">
                    <v-icon x-large color="white">
                      mdi-image-plus
                    </v-icon>
                  </v-btn>
                </div>
                <div v-else class="advertisement-content__preview">
                  <v-img height="130" contain :src="computedUrl()"> </v-img>
                  <v-btn
                    v-if="!readOnly"
                    absolute
                    x-small
                    fab
                    text
                    style="right: 0; top: 0"
                    @click="removeImage"
                  >
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <CmImageUploader
              style="display: none;"
              no-crop
              :hideButton="true"
              id="file-input-1"
              @set="handleImage"
            />
          </v-row>
          <v-row v-if="readOnly" class="mt-7 mb-4">
            <v-col>
              <span style="font-size: 18px; color: #000000">Аналитика</span>
              <v-row class="analytics mt-3">
                <v-col>
                  <div class="plan">
                    <div class="plan__title">
                      Планируемый бюджет
                    </div>
                    <div class="plan__value d-flex align-center">
                      {{ eventItem.budget | formatMoney }}
                      <v-icon small color="#000000">mdi-currency-kzt</v-icon>
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div class="fact">
                    <div class="fact__title">
                      Фактический бюджет
                    </div>
                    <div class="fact__value d-flex align-center">
                      {{ eventItem.usedBudget | formatMoney }}
                      <v-icon small color="#FFFFFF">mdi-currency-kzt</v-icon>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row class="analytics mt-5">
                <v-col>
                  <div class="plan">
                    <div class="plan__title">
                      Количество промокодов
                    </div>
                    <div class="plan__value">
                      {{ eventItem.totalAmount | formatMoney }}
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div class="fact">
                    <div class="fact__title">
                      Количество промокодов
                    </div>
                    <div class="fact__value">
                      {{ eventItem.usedAmount | formatMoney }}
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row class="analytics mt-4">
                <v-col>
                  <div class="plan">
                    <div class="plan__title">
                      Ставка
                    </div>
                    <div class="plan__value">
                      {{ eventItem.discount | formatMoney }}
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div class="fact">
                    <div class="fact__title">
                      Ставка
                    </div>
                    <div class="fact__value">
                      {{ eventItem.discount | formatMoney }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="!disabled" class="pb-5">
        <v-spacer />
        <v-btn
          class="px-5"
          outlined
          :disabled="loading"
          rounded
          color="primary"
          text
          @click="save()"
        >
          Сохранить
        </v-btn>
        <v-btn
          v-if="!readOnly && eventId"
          class="px-5"
          :disabled="loading"
          rounded
          color="primary"
          @click="publish()"
        >
          Опубликовать
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialog" max-width="440px">
      <v-card>
        <v-card-title class="justify-center">
          <v-icon color="black" large>mdi-information-outline</v-icon>
        </v-card-title>
        <v-card-text style="text-align: center" class="my-3">
          <span style="color: black; font-size: 18px">
            Вы действительно хотите <br />
            опубликовать данное мероприятие?
          </span>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            rounded
            outlined
            min-width="130px"
            depressed
            color="primary"
            text
            @click="dialog = false"
          >
            НЕТ
          </v-btn>
          <v-btn min-width="130px" color="primary" rounded @click="confirmPublish">ДА</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import mixin from '@/views/crm/marketplace/components/mixin';
import getEnv from '@/utils/env';

export default {
  mixins: [mixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: Number,
      default: 0,
    },
  },
  filters: {
    formatMoney(val) {
      if (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      }
      return '';
    },
  },
  data() {
    return {
      eventItem: {
        status: 'NEW',
        theme: null,
        startDate: null,
        finishDate: null,
        budget: null,
        discount: null,
        totalAmount: null,
        eventType: null,
        promoCode: null,
        message: null,
      },
      menuStartDate: false,
      menuFinishDate: false,
      imageFrontUrl: null,
      valid: true,
      loading: false,
      eventTypes: [
        { text: 'Новый клиент', value: 'NEW_CLIENT' },
        { text: 'Персональный', value: 'PERSONAL' },
        { text: 'Общий', value: 'COMMON' },
      ],
      budgetRule: [
        (v) => !!v,
        (v) => v >= this.eventItem.discount || 'Бюджет не может быть меньше ставки',
      ],
      dialog: false,
    };
  },
  async mounted() {
    if (this.eventId) {
      await this.getItemById(this.eventId);
    } else {
      this.eventItem = {
        status: 'NEW',
        theme: null,
        startDate: null,
        finishDate: null,
        budget: null,
        discount: null,
        totalAmount: null,
        eventType: null,
        promoCode: null,
        message: null,
      };
    }
  },
  computed: {
    date() {
      return this.formatDate(new Date());
    },
    startDate() {
      return this.formatDate(this.eventItem.startDate);
    },
    finishDate() {
      return this.formatDate(this.eventItem.finishDate);
    },
    eventStatusEnums() {
      return this.$store.getters['entity/getEntity']('eventStatusEnums');
    },
    eventStatusColorEnums() {
      return this.$store.getters['entity/getEntity']('eventStatusColorEnums');
    },
    readOnly() {
      return this.eventItem.status !== 'NEW';
    },
    disabled() {
      return this.eventItem.status === 'FINISHED';
    },
    totalAmountComputed() {
      if (this.eventItem.budget && this.eventItem.discount) {
        return Math.floor(this.eventItem.budget / this.eventItem.discount);
      }
      return null;
    },
  },
  methods: {
    async getItemById(id) {
      const response = await this.$api.get(`/api/v1/crm/event/${id}`);
      this.eventItem = { ...response };
      this.imageFrontUrl = this.eventItem.image;
    },
    upload() {
      document.querySelector('#file-input-1').click();
    },
    async handleImage(image) {
      await this.uploadImage(image);
    },
    async uploadImage(image) {
      this.loading = true;
      const bodyFormData = new FormData();
      const base64ToFile = await this.urlToFile(image);
      bodyFormData.append('file', base64ToFile);
      const response = await this.$image.post('/image/api/image', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      this.imageFrontUrl = response.data.path;
      this.loading = false;
    },
    async urlToFile({ url, file }) {
      const res = await fetch(url);
      const blob = await res.blob();
      return new File([blob], file.name, { type: file.type });
    },
    computedUrl() {
      const splitted = this.imageFrontUrl.split('/');
      const name = splitted.splice(splitted.length - 1, 1)[0];
      const path = splitted.join('/');
      return `${getEnv('VUE_APP_URL_MINIO')}/ecom/${path}/M_${name}`;
    },
    removeImage() {
      this.imageFrontUrl = null;
      this.frontFile = null;
      this.lastUpload = null;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const body = {
          theme: this.eventItem.theme,
          startDate: this.$moment(this.eventItem.startDate, 'YYYY-MM-DD').format(
            'YYYY-MM-DDTHH:mm:ss.sss[Z]',
          ),
          finishDate: this.$moment(
            this.eventItem.finishDate,
            'YYYY-MM-DD',
          ).format('YYYY-MM-DDT23:59:59.999[Z]'),
          budget: this.eventItem.budget,
          discount: this.eventItem.discount,
          totalAmount: this.eventItem.totalAmount,
          eventType: this.eventItem.eventType,
          promoCode: this.eventItem.promoCode,
          message: this.eventItem.message,
          image: this.imageFrontUrl,
          phoneNumber: this.formatPhone(this.eventItem.phoneNumber) || '',
          id: this.eventId || '',
        };

        this.$api[this.eventId ? 'put' : 'post']('/api/v1/crm/event', body)
          .then(() => {
            this.$store.dispatch('tools/setSnackbar', {
              type: 'success',
              message: 'Мероприятие создано!',
            });
            this.closeDialog();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    publish() {
      this.dialog = true;
    },
    confirmPublish() {
      this.dialog = false;
      this.$nextTick(() => {
        this.$api
          .get(`/api/v1/crm/event/publish/${this.eventId}`)
          .then(() => {
            this.$store.dispatch('tools/setSnackbar', {
              type: 'success',
              message: 'Мероприятие активировано!',
            });
            this.closeDialog();
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    formatDate(date) {
      if (date) {
        return this.$moment(date).format('DD/MM/YYYY');
      }
      return null;
    },
    formatPhone(phone) {
      if (phone) {
        return phone.replace(/[^\d]/g, '');
      }
      return null;
    },
    closeDialog() {
      this.$emit('close');
    },
    checkDot(event) {
      if (event.key === '.' || event.key === ',') {
        event.preventDefault();
      }
    },
    onlyEnglish(event) {
      const code = event.key.charCodeAt();
      if (code > 1039 && code < 1104) {
        event.preventDefault();
      }
    },
    makePromoCode(length = 8) {
      let result = '';
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result.toUpperCase();
    },
    generatePromocode() {
      const newPromo = this.makePromoCode();
      this.eventItem.promoCode = newPromo;
      navigator.clipboard.writeText(newPromo).then(() => {
        this.$store.dispatch('tools/setSnackbar', {
          type: 'success',
          message: `Промокод ${newPromo} скопирован в буфер обмена`,
        });
      });
    }
  },
};
</script>
<style scoped lang="scss">
.col {
  padding-top: 0;
  padding-bottom: 0;
}
.advertisement-content {
  &__image {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 267px;
    height: 100px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 12px;
  }
  &__preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 267px;
    padding-right: 30px;
    position: relative;
  }
}
.analytics {
  .plan {
    padding: 16px;
    background-color: #ebebeb;
    border-radius: 8px;
    color: #000000;

    &__title {
      font-size: 14px;
      font-weight: 400;
      line-height: 9px;
    }

    &__value {
      margin-top: 16px;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }
  }
  .fact {
    padding: 16px;
    background-color: #58c968;
    border-radius: 8px;
    color: #ffffff;

    &__title {
      font-size: 14px;
      font-weight: 400;
      line-height: 9px;
    }

    &__value {
      margin-top: 16px;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}
</style>
